import { Pipe, PipeTransform } from '@angular/core';
import { ShippingMethodEnum } from '../enums/shipping-method.enum';

@Pipe({
  name: 'shippingMethod',
  standalone: true,
})
export class ShippingMethodPipe implements PipeTransform {
  transform(value: string): string {
    switch (value as ShippingMethodEnum) {
      case ShippingMethodEnum.NoShippingMethod:
        return 'Nouto';
      case ShippingMethodEnum.DHL:
        return 'Postnord Home';
      case ShippingMethodEnum.MyPackHome:
        return 'Postnord Home';
      case ShippingMethodEnum.BudbeeHome:
        return 'Budbee Home';
      case ShippingMethodEnum.SmallPackage:
        return 'Pikkupaketti';
      case ShippingMethodEnum.SmallPackageV2:
        return 'Pikkupaketti';
      case ShippingMethodEnum.Package:
        return 'PostNord Collect';
      case ShippingMethodEnum.PackageV2:
        return 'PostNord Collect';
      case ShippingMethodEnum.PackageV3:
        return 'PostNord Collect';
      case ShippingMethodEnum.PackageLarge:
        return 'PostNord Pallet';
      case ShippingMethodEnum.PackageLargeV2:
        return 'PostNord Pallet';
      case ShippingMethodEnum.PackageLargeV3:
        return 'PostNord Pallet';
      case ShippingMethodEnum.ExpressPackage:
        return 'Express-paketti';
      case ShippingMethodEnum.ThirdParty:
        return 'Tavarantoimittaja';
      case ShippingMethodEnum.Digital:
        return 'Digitaalinen toimitustapa';
      default:
        return '';
    }
  }
}
