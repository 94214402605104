import { Component } from '@angular/core';
import { CamReaderComponent } from '../../components/cam-reader/cam-reader.component';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { RedemptionsComponent } from '../../components/redemptions/redemptions.component';

@Component({
  selector: 'app-cam',
  standalone: true,
  imports: [
    CamReaderComponent,
    MatFabButton,
    MatIcon,
    RouterLink,
    RedemptionsComponent,
  ],
  templateUrl: './cam.component.html',
  styleUrl: './cam.component.scss',
})
export class CamComponent {}
