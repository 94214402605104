<!--<app-cam-reader></app-cam-reader>-->

<div class="absolute right-0 top-0 z-40">
  <div class="flex flex-col">
    <div class="m-2">
      <button mat-fab routerLink="/cam">
        <mat-icon>videocam</mat-icon>
      </button>
    </div>
    <div class="m-2">
      <button mat-fab routerLink="/settings">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>
</div>

<app-bar-reader></app-bar-reader>

<div class="flex justify-center">
  <div class="w-full 2xl:w-1/2">
    <app-redemptions></app-redemptions>
  </div>
</div>

