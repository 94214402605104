import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AutofocusComponent } from '../../components/settings/autofocus/autofocus.component';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { SettingsService } from '../../core/services/settings.service';
import { CameraComponent } from '../../components/settings/camera/camera.component';
import packageJson from '../../../../package.json';
import { RedemptionsLogSizeComponent } from '../../components/settings/redemptions-log-size/redemptions-log-size.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    AutofocusComponent,
    MatFabButton,
    MatIcon,
    RouterLink,
    CameraComponent,
    RedemptionsLogSizeComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {
  public version: string = packageJson.version;
  public constructor(
    private settingsService: SettingsService,
    private _location: Location,
  ) {}
  reset() {
    this.settingsService.reset();
  }

  backClicked() {
    this._location.back();
  }
}
