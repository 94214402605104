@for (redemption of redemptions.slice().reverse(); track redemption.redemptionCode) {
  @if (redemption?.redemption?.postNordTrackingID) {
    <app-delivery [redemption]="redemption"></app-delivery>
  } @else if (redemption?.redemption?.shippingMethod === 'budbee_home') {
    <app-delivery [redemption]="redemption"></app-delivery>
  } @else if (redemption?.redemption?.redemptionType === 'fetch') {
    <app-fetch [redemption]="redemption"></app-fetch>
  } @else {
    <app-notfound [redemption]="redemption"></app-notfound>
  }
} @empty {
  <mat-card class="m-4">
    <mat-card-content>Ei lunastuksia.</mat-card-content>
  </mat-card>
}
